import Vue from 'vue';

export default{
    getSucursales(parametros){
        return Vue.axios.get("sucursales/find", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getSucursal(id){
        return Vue.axios.get("sucursales/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateSucursal(parametros){
        return Vue.axios.put("sucursales", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteSucursal(id){
        return Vue.axios.delete("sucursales/"+ id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    addSucursal(parametros){
        return Vue.axios.post("sucursales", parametros ).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getSucursalesDepartamentos(parametros){
        return Vue.axios.get("sucursales/complete", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
}